import './dep-project-row.css'
import {DeploymentProjectStatus} from "../../domain";
import DeploymentEnvInfo from "./deployment-env-info";

const DepProjectRow = (props: { project: DeploymentProjectStatus }) => {

    const devRel = props.project.definedEnvironments.dev?.deploymentResult?.version
    const testRel = props.project.definedEnvironments.test?.deploymentResult?.version
    const prodRel = props.project.definedEnvironments.prod?.deploymentResult?.version
    const pprdRel = props.project.definedEnvironments.pprd?.deploymentResult?.version

    return (
        <tr className='dep-project-row-container'>
            <td>
                <a href={props.project.bambooUrl} target="_blank" rel="noreferrer">
                    {props.project.name}
                </a>
            </td>
            <td><DeploymentEnvInfo
                env={props.project.definedEnvironments.dev}
                hasLatest={true}
                prodEnv={props.project.definedEnvironments.prod}
                projectId={props.project.projectId}
                projectName={props.project.name}/>
            </td>
            <td><DeploymentEnvInfo
                env={props.project.definedEnvironments.test}
                hasLatest={devRel === testRel}
                prodEnv={props.project.definedEnvironments.prod}
                projectId={props.project.projectId}
                projectName={props.project.name}/>
            </td>
            <td><DeploymentEnvInfo
                env={props.project.definedEnvironments.prod}
                hasLatest={devRel === prodRel}
                prodEnv={props.project.definedEnvironments.prod}
                projectId={props.project.projectId}
                projectName={props.project.name}/>
            </td>
            <td><DeploymentEnvInfo
                env={props.project.definedEnvironments.pprd}
                hasLatest={devRel === pprdRel}
                prodEnv={props.project.definedEnvironments.prod}
                projectId={props.project.projectId}/>
            </td>
        </tr>
    )
}

export default DepProjectRow