import './deployment-grid.css'
import {DeploymentProjectStatus} from "../../domain";
import DepProjectRow from "./dep-project-row";

const DeploymentGrid = (props: { projects: DeploymentProjectStatus[] }) => {
    return (
        <div className='deployment-grip-container'>
            <table>
                <thead>
                <tr>
                    <th>Deployment Project</th>
                    <th>DEV (Pending)</th>
                    <th>TEST (UAT)</th>
                    <th>PROD (LIVE)</th>
                    <th>PPRD (Prod Support)</th>
                </tr>
                </thead>
                <tbody>
                {props.projects.map((proj, index) => <DepProjectRow key={index} project={proj}/> )}
                </tbody>
            </table>
        </div>)
}

export default DeploymentGrid