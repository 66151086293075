const buildBambooCompareToUrl = (
  oldVersionName: string,
  newVersionId: string,
  projectId: string
) => {
  return `https://bamboo.liberty.edu/deploy/viewDeploymentVersionJiraIssues.action?compareWithVersion=${encodeURIComponent(
    oldVersionName
  )}&save=Compare&versionId=${newVersionId}&deploymentProjectId=${projectId}`
}

export default buildBambooCompareToUrl
