import './header.scss'
import {NavLink} from "react-router-dom";

const Header = () => (
    <div className="header-container sticky-top">
        <div className="header-inner-container">
            <div className="header-column">
                <h1>PI Standard Change Dashboard</h1>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg">
            <ul>
                <NavLink
                    className={({isActive}) => `nav-bar-menu-item ${isActive ? 'active' : ''}`}
                    to="deployment-projects">
                    Deployment Projects
                </NavLink>
                <NavLink
                    className={({isActive}) => `nav-bar-menu-item ${isActive ? 'active' : ''}`}
                    to="change-form">
                    Standard Change Form
                </NavLink>
                <NavLink
                    className={({isActive}) => `nav-bar-menu-item ${isActive ? 'active' : ''}`}
                    to="change-history">
                    Change History
                </NavLink>
            </ul>
        </nav>
    </div>
)

export default Header
