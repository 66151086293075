import "./App.css"
import {useEffect} from "react"
import StandardChangeForm from "./standard-change-form/standard-change-form";
import {Navigate, Route, Routes} from "react-router-dom";
import DeploymentProjects from "./deployment-projects/deployment-projects";
import {initialize} from "./redux/global/actions";
import {useAppDispatch} from "./redux/store";
import ChangeHistory from "./change-history/change-history";

const App = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(initialize())
    }, [])

    return (
        <div className="app-container">
            <Routes>
                <Route index element={<NotFound />} />
                <Route path="deployment-projects" element={<DeploymentProjects/>}/>
                <Route path="change-form" element={<StandardChangeForm/>}/>
                <Route path="change-history" element={<ChangeHistory />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    )
}

export default App

const NotFound = () => <Navigate to="deployment-projects" replace />
