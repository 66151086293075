import { ChangeHistoryItem } from "./domain"
import "./change-history.scss"
import { useSelector } from "react-redux"
import { ChangeHistoryStateInterface } from "../redux/change-history/slice"
import useHistoryRefresher from "./use-history-refresher"
import buildBambooCompareToUrl from "../utils/compare-to-url"
import { Fragment } from "react"

const ChangeHistory = () => {
  const { changeHistory } = useSelector(
    ({ changeHistory }: { changeHistory: ChangeHistoryStateInterface }) =>
      changeHistory
  )

  useHistoryRefresher()

  return (
    <div className="change-history-container card">
      <h3>Change History</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Project</th>
            <th>Change Num</th>
            <th>From Version</th>
            <th>To Version</th>
            <th>Deployment Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {changeHistory.map((item, index) => (
            <ChangeHistoryRow key={index} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ChangeHistory

const ChangeHistoryRow = (props: { item: ChangeHistoryItem }) => {
  const buildCompareToLink = (item: ChangeHistoryItem) => {
    const versionToReplace =
      props.item.changeRequest.parameters.versionToReplace?.name

    // in case first deployment
    if (!versionToReplace) return <Fragment />

    const url = buildBambooCompareToUrl(
      versionToReplace,
      item.changeRequest.parameters.versionToDeploy.id,
      item.changeRequest.parameters.projectId
    )
    return (
      <a target="_blank" rel="noreferrer" href={url}>
        Compare
      </a>
    )
  }

  return (
    <tr>
      <td>
        <a
          href={props.item.links.deploymentProject}
          target="_blank"
          rel="noreferrer"
        >
          {props.item.changeRequest.parameters.projectName}
        </a>
      </td>
      <td>
        <a
          href={props.item.links.changeRequest}
          target="_blank"
          rel="noreferrer"
        >
          {props.item.changeRequest.number}
        </a>
      </td>
      <td>{props.item.changeRequest.parameters.versionToReplace?.name}</td>
      <td>
        <div>
          {props.item.changeRequest.parameters.versionToDeploy.name}
          &nbsp;{buildCompareToLink(props.item)}
        </div>
      </td>
      <td>
        <a
          href={props.item.links.deploymentResult}
          target="_blank"
          rel="noreferrer"
        >
          {props.item.deploymentStatus}
        </a>
      </td>
      <td>{new Date(props.item.entryDate).toLocaleString()}</td>
    </tr>
  )
}
