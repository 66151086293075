import axios, {AxiosError, AxiosResponse} from "axios"
import {SERVICE_URL} from "./service-url";

const REDIRECT_URL = `${SERVICE_URL}/api/login/auth-and-redirect?url=${encodeURIComponent(window.location.href.replace(window.location.hash, ''))}`

const instance = axios.create({
    baseURL: "",
    withCredentials: true
});

const ignoredStatus: number[] = [404]

instance.interceptors.response.use(
    (response: AxiosResponse) => Object.assign(response, {ok: (response.status >= 200 && response.status < 300)}),
    (error: AxiosError) => {
        if (!!error && !!error.response) {
            if (error.response?.status === 401) window.location.href = REDIRECT_URL
        }

        throw error
    }
);

export default instance
