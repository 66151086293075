import { useDispatch } from "react-redux"
import { fetchChangeHistory } from "../redux/change-history/actions"
import { AppDispatch } from "../redux/store"
import { SERVICE_URL } from "../utils/service-url"

const useHistoryRefresher = () => {
  const dispatch = useDispatch<AppDispatch>()

  const evtSource = new EventSource(
    `${SERVICE_URL}/api/change-history/events`,
    { withCredentials: true }
  )
  evtSource.onmessage = (event) => {
    if (event.data === "UPDATE") {
      console.log("got change history updated event")
      dispatch(fetchChangeHistory())
    }
  }
  evtSource.onerror = (error) => {
    console.log("error from event source", error)
  }
}

export default useHistoryRefresher
